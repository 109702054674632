/*
    Internal ERP API
*/

frappe.provide("erp_api");

$.extend(erp_api, {
    get_existing_emails: function(email, freeze, freeze_message){
        return new Promise((resolve, reject)=>{       
            frappe.call({
                method: "bond_assets.erp_api.api.get_list_data",
                args:{
                    filters: {
                        email_id:email
                    },
                    doctype: "Lead",
                },
                freeze: freeze,
                freeze_message: freeze_message,
                callback: (res)=>{
                    resolve(res.message)
                },
                error: ()=>[
                    reject()
                ]
            });
        });
    },

    save_doc: (doc, method)=>{
        return new Promise((resolve, reject)=>{
            frappe.call({
                method: "bond_assets.erp_api.api.save_doc",
                args:{
                    doc: doc,
                    method: method,
                },
                freeze: true,
                freeze_message: __(doc.freeze_message || "Please wait while we're process your data..."),
                callback: (res)=>{
                    resolve(res);
                },
                error:(e)=>{
                    reject(e);
                }
            });
        });
    },

    get_list: (doctype, filters, fields, freeze=false, freeze_message="")=>{
        return new Promise((resolve, reject)=>{
            frappe.call({
                method: "bond_assets.erp_api.api.get_data",
                args:{
                    doctype: doctype,
                    filters: filters,
                    fields: fields,
                },
                freeze: freeze,
                freeze_message: freeze_message,
                callback: (res)=>{
                    resolve(res.message?res.message:[]);
                },
                error: (e)=>{
                    reject(e);
                }
            });
        });
    }
});