/*
    Scan ID
*/

frappe.provide("id_scanning");

$.extend(id_scanning, {

    scan_barcode: function(data){
        // data must be in format of base64 algo
        return this.get_data("scan_barcode", data);
    },
    scan_ocr: function(){

    },
    get_data: function(method, data){
        return new Promise((resolve, reject)=>{
            method = "insurance_services.insurance_erp.doctype.id_scan_settings.id_scan_settings."+cstr(method);
            frappe.call({
                method: "insurance_services.utils.data_services.call_method",
                args:{
                    method: method,
                    filters: {
                        data: data
                    }
                },
                freeze: true,
                callback: (res)=>{
                    return resolve(res);
                },
                error: ()=>{
                    reject();
                }
            })
        });
    }
});
