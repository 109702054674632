/*
    Quotes Controller
*/

frappe.provide("insurance_api.quotes");

$.extend(insurance_api.quotes, {
    see_quotes: function(values, freeze=false, freeze_message=""){
        return new Promise((resolve, reject)=>{
            frappe.call({
                method: "bond_assets.insurance_api.data.see_quotes",
                args:{
                    values: values
                },
                freeze: freeze,
                freeze_message: freeze_message || "",
                callback: (res)=>{
                    resolve(res);
                },
                error: (res)=>{
                    reject(res);
                }
            });
        });
    }
});