/*
    Write your  Website related JS code here
*/
frappe.provide("vendor_api.vehicle");

$.extend(vendor_api.vehicle, {
    vehicle_lookup_using_plate: function(license_plate_number, state){
        return vendor_api.vehicle.call_api("vehicle", "vehicle_lookup_using_plate", {
            license_plate_number: license_plate_number,
            state: state
        });
    },
    call_api: function(api_type, method, data){
        return new Promise((resolve, reject) => {
            frappe.call({
                method: "bond_assets.vendor_api.api.call_api",
                args: {
                    data: data || {},
                    api_type: api_type,
                    method: method
                },
                freeze: true,
                callback: function(r) {
                    resolve(r.message);
                },
                error: function(r) {
                    reject(r.message);
                }
            });
        });
    }
});