/*
    Turbo Rater data services for website
*/
frappe.provide("turbo_rater.data_services");
frappe.provide("turbo_rater.utils");

$.extend(turbo_rater.utils, {
    get_data: function(doctype, filters, fields=["name"]){
        return new Promise((resolve, reject)=>{
            frappe.call({
                method: "bond_assets.insurance_api.data.get_data",
                args: {
                    doctype: doctype,
                    filters: filters,
                    fields: fields,
                },
                freeze: true,
                callback: (res)=>{
                    return resolve(res);
                },
                error: ()=>{
                    reject();
                }
            });
        });
    },
    get_vin_details: function(args){
        return turbo_rater.utils.call_auto_method("get_vin_details", args);
    },
    get_years: function(args){
        return turbo_rater.utils.call_auto_method("get_years", {});
    },
    get_makes: function(args){
        return turbo_rater.utils.call_auto_method("get_makes", args);
    },
    get_models: function(args){
        return turbo_rater.utils.call_auto_method("get_models", args);
    },
    get_model_details: function(args){
        return turbo_rater.utils.call_auto_method("get_model_details", args);
    },
    get_home_valuation_details: function(args){
        return turbo_rater.utils.call_home_method("home_lookup", args);
    },
    call_auto_method: function(method, args){
        method = "insurance_services.utils.auto."+cstr(method)
        return turbo_rater.utils.call_method(method, args);
    },
    call_home_method: function(method, args){
        method = "insurance_services.utils.home."+cstr(method);
        return turbo_rater.utils.call_method(method, args);
    },

    call_method: function(method, args, callback){
        return new Promise((resolve, reject)=>{
            frappe.call({
                method: "bond_assets.insurance_api.data.call_method",
                args:{
                    method: method,
                    filters: args
                },
                freeze: true,
                callback: (res)=>{
                    return resolve(res);
                },
                error: ()=>{
                    reject();
                }
            });
        });
    }
});

