frappe.provide("data_services");

import "bond_assets/public/js/rater_api/data";
import "bond_assets/public/js/erp_api/api";
import "./id_scan.js";
import "bond_assets/public/js/vendor_api/vehicle.js";
import "./quotes";

$.extend(data_services, {
    get_and_set_data: function(form, field, doctype, filters, label){
        return turbo_rater.utils.get_data(doctype, filters).then((res)=>{
            let results = [];
            if(res && res.message){
                $.each(res.message, (idx, item)=>{
                    results.push(item.name);
                });
            }
            field.set_options(results);
        });
    },
    get_api_data: function(doctype, filters={}, fields=[]){
        // return the promise
        return turbo_rater.utils.get_data(doctype, filters, fields);
    },
    get_and_set_method_data: function(form, field, method, filters, callback){
        return turbo_rater.utils[method](filters || {}).then((res)=>{
            callback && callback(res);
        });
    },

    validate_address: function(address, service_type){
        try{
            let state = cstr(address.state).trim();

            if(frappe.boot.states[state.toUpperCase()]){
                state = state.toUpperCase();
            }else if(frappe.boot.long_to_short_map[frappe.utils.to_title_case(state)]){
                for(var long_state in frappe.boot.long_to_short_map){
                    if(long_state == frappe.utils.to_title_case(state)){
                        state = frappe.boot.long_to_short_map[long_state];
                        break;
                    }
                }
            }else{
                state = "";
            }
            let enabled_states = frappe.boot.enabledStates[service_type];
            if(!in_list(enabled_states, state)){
                let popup = frappe.popup_print("We do not currently service your area.", "", "Back to Home", ()=>{
                    window.location.href = "https://www.trybond.com";
                });
                popup.$wrapper.addClass("do-not-serve-in-your-area");
                popup.$wrapper.find(".btn-close").hide();
            }
        }catch(e){
            console.log(e);
        }
    }
});